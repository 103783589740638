/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AuthAndApiContext, BreakpointContext } from '../../contexts';
import { ReactComponent as Arena } from './arena.svg';
import { ReactComponent as FilledArena } from './arenaFilled.svg';
import styles from './BottomNavbar.module.css';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as Sword } from './sword.svg';
/* import { ReactComponent as SignUp } from './signUp.svg'; */
import { ReactComponent as SignIn } from './signIn.svg';
import { sendGAEvent } from '../../helpers';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['nav']>,
  {
    setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }
>;

export default function BottomNavbar({
  className,
  setIsSidebarOpen,
  ...props
}: Props) {
  const router = useRouter();
  const { device } = useContext(BreakpointContext);
  const { user } = useContext(AuthAndApiContext);
  const inLanding =
    router.pathname === '/landing/v1' || router.pathname === '/landing/v2';

  return device === 'desktop' ? null : (
    <nav
      {...props}
      className={classNames(
        styles.bottomNavbar,
        user === null && styles.notAuthenticated,
        className,
      )}
    >
      <div
        className={classNames(
          styles.container,
          router.pathname === '/games' && styles.games,
        )}
      >
        <Link href='/arena'>
          <a
            className={classNames(
              styles.navItem,
              router.pathname.startsWith('/arena') && styles.active,
            )}
          >
            {router.pathname.startsWith('/arena') ? <FilledArena /> : <Arena />}

            <p>
              <FormattedMessage defaultMessage='Arena' />
            </p>
          </a>
        </Link>

        <button
          className={styles.navItem}
          onClick={() => {
            window?.Intercom('show');
          }}
          type='button'
        >
          <Support />

          <p>
            <FormattedMessage defaultMessage='Support' />
          </p>
        </button>

        {user === null && (
          <Link
            href={inLanding ? `${router.pathname}/?action=sign-in` : '/'}
            scroll={false}
          >
            <a
              className={classNames(
                styles.navItem,
                router.pathname.startsWith('/arena') && styles.active,
              )}
            >
              <SignIn />

              <p>
                <FormattedMessage defaultMessage='Sign In' />
              </p>
            </a>
          </Link>
        )}

        {user !== null && (
          <div
            className={classNames(styles.navItem, styles.quickDuel)}
            onClick={() =>
              sendGAEvent({
                category: 'Navigation Bar',
                event: 'Click Play',
                label: 'Quick Duel',
              })
            }
          >
            <Link href='/quick-duel'>
              <a className={styles.circle}>
                <Sword />
              </a>
            </Link>

            <p>
              <FormattedMessage defaultMessage='Play' />
            </p>
          </div>
        )}

        {/* {user === null && (
          <div className={classNames(styles.navItem, styles.quickDuel)}>
            <Link href={`${router.pathname}/?action=sign-up`} scroll={false}>
              <a className={styles.circle}>
                <SignUp />
              </a>
            </Link>

            <p>
              <FormattedMessage defaultMessage='Sign Up' />
            </p>
          </div>
        )} */}

        {user !== null && (
          <Link href='https://discord.com/invite/gamer-arena-652064236083871745'>
            <a
              className={classNames(
                styles.navItem,
                router.pathname.startsWith('/chat') && styles.active,
              )}
              target='_blank'
            >
              <Chat />

              <p>
                <FormattedMessage defaultMessage='Chat' />
              </p>
            </a>
          </Link>
        )}

        {router.pathname !== '/games' && (
          <button
            className={styles.navItem}
            onClick={() => setIsSidebarOpen((prev) => !prev)}
            type='button'
          >
            <Menu />

            <p>
              <FormattedMessage defaultMessage='Menu' />
            </p>
          </button>
        )}
      </div>
    </nav>
  );
}
