import React, { useContext } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { createGtag, sendGAEvent } from '../../helpers';
import Container from '../Container';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import { ReactComponent as Discord } from './discord.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import styles from './Footer.module.css';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { AuthAndApiContext } from '../../contexts';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['footer']> & {
  className?: string;
};

type SocialLink = {
  icon: React.ReactNode;
  link: string;
  name: string;
};

const socials: SocialLink[] = [
  {
    icon: <Instagram />,
    link: 'https://www.instagram.com/gamerarena_com/',
    name: 'instagram',
  },
  {
    icon: <Facebook />,
    link: 'https://www.facebook.com/gamerarenacom/',
    name: 'facebook',
  },
  {
    icon: <Twitter />,
    link: 'https://twitter.com/gamerarena_com',
    name: 'twitter',
  },
  {
    icon: <Discord />,
    link: 'https://discord.com/invite/SFhnqCd',
    name: 'discord',
  },
];

export default function Footer({ className, ...props }: Props): JSX.Element {
  const router = useRouter();
  const { user } = useContext(AuthAndApiContext);

  return (
    <footer {...props} className={classNames(styles.footer, className)}>
      <Container className={styles.container} fluid>
        <div className={styles.column}>
          <Logo className={styles.logo} />

          <p className={styles.notice}>
            <FormattedMessage
              defaultMessage='Gamer Arena is not endorsed by, directly affiliated with, maintained or sponsored by Electronic Arts,
            Activision Blizzard, Valve Corporation, Miniclip, Epic Games, Blizzard Entertainment, Microsoft, Xbox,
            Sony or Playstation. All content, games titles, trade names and/or trade dress, trademarks, artwork and
            associated imagery are trademarks and/or copyright material of their respective owners.'
            />
          </p>

          <p className={styles.copyright}>
            {`© ${new Date().getFullYear()} Gamer Arena`}
          </p>
        </div>

        <div className={classNames(styles.column, styles.menu)}>
          <nav
            className={classNames(
              styles.navigation,
              user && styles.authenticated,
            )}
          >
            <div className={styles.navColumn}>
              <p className={styles.navHeader}>
                <FormattedMessage defaultMessage='Explore' />
              </p>
              <a
                href='http://about.gamerarena.com/'
                onClick={() => {
                  sendGAEvent({ event: 'Footer About Click' });
                }}
                rel='noopener noreferrer'
                target='_blank'
              >
                <FormattedMessage defaultMessage='About Us' />
              </a>
              <a
                href='https://coinmarketcap.com/currencies/gamer-arena/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Coinmarketcap
              </a>
              <a
                href='https://www.coingecko.com/en/coins/gamer-arena'
                rel='noopener noreferrer'
                target='_blank'
              >
                Coingecko
              </a>
            </div>
            <div className={styles.navColumn}>
              <p className={styles.navHeader}>
                <FormattedMessage defaultMessage='Community' />
              </p>
              <a
                href='https://discord.com/invite/gamer-arena-652064236083871745'
                rel='noopener noreferrer'
                target='_blank'
              >
                Discord
              </a>
              <a
                href='https://twitter.com/gamerarena_com'
                rel='noopener noreferrer'
                target='_blank'
              >
                Twitter
              </a>
              <a
                href='https://www.instagram.com/gamerarena_com/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Instagram
              </a>
              <a
                href='https://www.facebook.com/gamerarenacom/'
                rel='noopener noreferrer'
                target='_blank'
              >
                Facebook
              </a>
              <a
                href='https://t.me/GamerArena_GAU'
                rel='noopener noreferrer'
                target='_blank'
              >
                Telegram
              </a>
            </div>
            <div className={styles.navColumn}>
              <p className={styles.navHeader}>
                <FormattedMessage defaultMessage='Platform' />
              </p>
              <Link href='/'>
                <a>
                  <FormattedMessage defaultMessage='Homepage' />
                </a>
              </Link>

              <a
                href='http://help.gamerarena.com/'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FormattedMessage defaultMessage='Help & Support' />
              </a>

              <a
                href='mailto:info@gamerarena.com'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FormattedMessage defaultMessage='Corporate Contact' />
              </a>

              <Link
                href={
                  router.pathname === '/landing/v1' ||
                  router.pathname === '/landing/v2'
                    ? `${router.pathname}/?action=FAQ`
                    : '/landing/v1/?action=FAQ'
                }
                scroll={false}
              >
                <a
                  data-gtag={createGtag({
                    category: 'Footer',
                    event: 'Click CTA',
                    label: 'FAQ',
                    value: '/#FAQ',
                  })}
                >
                  <FormattedMessage defaultMessage='FAQ' />
                </a>
              </Link>

              <Link href='/terms-of-service'>
                <a>
                  <FormattedMessage defaultMessage='Terms of Service' />
                </a>
              </Link>

              <Link href='/privacy-policy'>
                <a>
                  <FormattedMessage defaultMessage='Privacy Policy' />
                </a>
              </Link>
            </div>
          </nav>
        </div>

        <div className={classNames(styles.column, styles.lastCol)}>
          <LanguageSelector />

          <div className={styles.social}>
            {socials.map(({ icon, link, name }) => (
              <a
                aria-label={name}
                className={styles.socialLink}
                href={link}
                key={name}
                rel='noopener noreferrer'
                target='_blank'
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  );
}
