/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useContext, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Button, HeaderButton, Modal } from '../../components';
import useMetamask from '../../hooks/useMetamask';
import styles from './metamask.module.css';
import { ReactComponent as Wallet } from './metamask.svg';
import { ReactComponent as Exclamation } from './exclamation.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ToastsContext } from '../../contexts';
import { sendGAEvent } from '../../helpers';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  device?: string;
};

const Metamask = ({ device }: Props) => {
  const { connect, setMetaState, metaState } = useMetamask();
  const [provider] = useState(window.ethereum);
  const intl = useIntl();
  const { addToast } = useContext(ToastsContext);
  const [web3interface] = useState('ethers');
  const [isNoExtensionModalOpen, setIsNoExtensionModalOpen] = useState(false);
  const [clickedDownloadButton, setClickedDownloadButton] = useState(false);
  const [isConnectedModalOpen, setIsConnectedModalOpen] = useState(false);
  const [isCopiedToClipBoard, setIsCopiedToClipBoard] = useState(false);

  const connectMetamask = (fromButton?: string) => {
    if (metaState.isAvailable && !metaState.isConnected) {
      (async () => {
        try {
          if (web3interface === 'ethers') {
            await connect(ethers.providers.Web3Provider, 'any', fromButton);
            const providerr = new ethers.providers.Web3Provider(
              window.ethereum,
              'any',
            );
            const networks = {
              avax: {
                chainId: `0x${Number(43114).toString(16)}`,
                chainName: 'Avalanche C-Chain',
                nativeCurrency: {
                  name: 'Avalanche',
                  symbol: 'AVAX',
                  decimals: 18,
                },
                rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                blockExplorerUrls: ['https://www.avax.network/'],
              },
              fuji: {
                chainId: `0x${Number(43113).toString(16)}`,
                chainName: 'Avalanche Fuji Testnet',
                nativeCurrency: {
                  name: 'Avalanche',
                  symbol: 'AVAX',
                  decimals: 18,
                },
                rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
                blockExplorerUrls: [
                  'https://cchain.explorer.avax-test.network',
                ],
              },
            };
            const chainId = 43114;

            const currentChainId = await providerr
              .getNetwork()
              .then((network) => network.chainId);
            // Eğer şu anki blok zinciri istediğimiz blok zinciri değilse
            if (chainId !== currentChainId) {
              try {
                // Metamask'ı istediğimiz blok zincirine geçir
                await providerr.send('wallet_switchEthereumChain', [
                  { chainId: ethers.utils.hexlify(chainId) },
                ]);
                console.log(
                  `Metamask switched to chainid : ${chainId} succesfully`,
                );
              } catch (err) {
                console.log(
                  `Error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`,
                );
                // Eğer blok zinciri bulunamadıysa ekle
                if (err.code === 4902 || err.code === -32603) {
                  try {
                    await providerr.send('wallet_addEthereumChain', [
                      networks.avax,
                    ]);
                  } catch (err) {
                    console.log(
                      `Error ocuured while adding new chain with chainId:${networks.avax.chainId}, err: ${err.message}`,
                    );
                  }
                }
              }
            }
          } else if (web3interface === 'web3') {
            await connect(Web3, fromButton);
          } else {
            throw Error(`Unknown web3 interface: ${web3interface}`);
          }
        } catch (error) {
          // console.log(error);
        }
      })();
    }
  };

  const onClose = () => {
    setIsNoExtensionModalOpen(false);
    setIsConnectedModalOpen(false);
    setClickedDownloadButton(false);
  };

  const disconnectMetamask = () => {
    onClose();
    localStorage.removeItem('metamaskConnected');
    const metamaskStorage = localStorage.getItem('metamaskConnected');
    if (!metamaskStorage) {
      setMetaState({
        type: 'SET_CONNECTED',
        payload: false,
      });
    }
    setMetaState({
      type: 'SET_ACCOUNT',
      payload: [],
    });
    addToast({
      content: intl.formatMessage({
        defaultMessage: 'Disconnected from metamask',
      }),
      kind: 'success',
    });
    sendGAEvent({
      category: 'Connect Wallet',
      event: 'Click Disconnect',
      label: 'Crypto Wallet',
    });
  };

  const truncate = (str: string) => {
    if (str && str?.length > 25) {
      const newStr = `${str.substring(0, 6)}...${str.substring(
        str.length - 4,
      )}`;
      return newStr.toUpperCase();
    }
    return '';
  };

  const metamaskConnected = localStorage.getItem('metamaskConnected');

  const downloadButtonOnClick = () => {
    setClickedDownloadButton(true);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const copyTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopiedToClipBoard(true);
    setTimeout(() => {
      setIsCopiedToClipBoard(false);
    }, 2000);
    sendGAEvent({
      category: 'Connect Wallet',
      event: 'Click Copy',
      label: 'Crypto Wallet',
    });
  };

  useEffect(() => {
    if (metaState.isConnected || metamaskConnected) {
      connectMetamask();
    }
  }, [
    metaState.isAvailable,
    web3interface,
    metaState.isConnected,
    metamaskConnected,
  ]);

  useEffect(() => {
    isConnectedModalOpen &&
      sendGAEvent({
        category: 'Connect Wallet',
        event: 'View Pop-Up',
        label: 'Crypto Wallet',
      });
  }, [isConnectedModalOpen]);

  return (
    <div className={styles.metamask}>
      {isConnectedModalOpen && (
        <Modal
          className={styles.connectedModal}
          isOpen={isConnectedModalOpen}
          onClose={() => onClose()}
          variant='secondary'
        >
          <h4>
            <FormattedMessage defaultMessage='Your Wallet is Connected' />
          </h4>
          <p>
            <FormattedMessage defaultMessage='Your wallet is connected to Gamer Arena.' />
          </p>
          <div className={styles.accountId}>
            {isCopiedToClipBoard && (
              <div className={styles.copiedFeedback}>
                <FormattedMessage defaultMessage='Link Copied!' />
              </div>
            )}
            <div className={styles.onlineIndicator} />
            <span className={classNames(styles.modalAccountId)}>
              {String(metaState.account[0])}
            </span>
            <div className={styles.divider} />
            <Copy
              className={styles.copy}
              onClick={() => copyTextToClipboard(String(metaState.account[0]))}
            />
          </div>
          <button
            className={styles.button}
            onClick={() => disconnectMetamask()}
            type='button'
          >
            <FormattedMessage defaultMessage='Disconnect wallet' />
          </button>
        </Modal>
      )}
      {isNoExtensionModalOpen && (
        <Modal
          className={styles.noExtensionModal}
          isOpen={isNoExtensionModalOpen}
          onClose={() => onClose()}
          variant='secondary'
        >
          {!clickedDownloadButton && <Exclamation />}

          {device === 'desktop' && clickedDownloadButton ? (
            <h4>
              <FormattedMessage defaultMessage='Please refresh your browser after installing the extension' />
            </h4>
          ) : (
            <h4>
              {/* Web3 uyumlu bir tarayıcı kullanın veya metamask eklentisini indirin. */}
              {/* Web3 uyumlu bir tarayıcı kullanın veya bu sayfayı Metamask uygulaması tarayıcısında açın. */}
              {device === 'desktop' ? (
                <FormattedMessage defaultMessage='Use a web3 compatible browser or download the metamask extension.' />
              ) : (
                <FormattedMessage defaultMessage='Use a Web3 compatible browser or open this page in the Metamask app browser.' />
              )}
            </h4>
          )}

          {device === 'desktop' && clickedDownloadButton && (
            <p>
              <FormattedMessage
                defaultMessage='After reloading the page, resume your login process with the
              wallet extension you installed'
              />
            </p>
          )}
          {device === 'desktop' && clickedDownloadButton ? (
            <Button
              className={styles.downloadButton}
              href='https://metamask.io/download/'
              onClick={reloadPage}
              rel='noreferrer'
              target='_blank'
            >
              <FormattedMessage defaultMessage='Reload Page' />
            </Button>
          ) : (
            <>
              {device === 'desktop' ? (
                <Button
                  className={styles.button}
                  href='https://metamask.io/download/'
                  onClick={downloadButtonOnClick}
                  rel='noreferrer'
                  target='_blank'
                >
                  <FormattedMessage defaultMessage='Download Metamask Extension' />
                </Button>
              ) : (
                <div className={styles.button}>
                  <a
                    className={styles.downloadApp}
                    onClick={() => {
                      window.open(
                        `https://metamask.app.link/dapp/${
                          process.env.NEXT_PUBLIC_GAMERARENA_STAGE === 'staging'
                            ? 'staging.gamerarena.com'
                            : 'gamerarena.com'
                        }`,
                        '_blank',
                        'noreferrer',
                      );
                    }}
                  >
                    <FormattedMessage defaultMessage='Download Metamask App' />
                    {/* <FormattedMessage defaultMessage='Continue' /> */}
                  </a>
                </div>
              )}
            </>
          )}
        </Modal>
      )}

      <HeaderButton
        className={classNames(
          styles.metamaskButton,
          device === 'mobile' && styles.forMobile,
        )}
        onClick={() => {
          if (!metaState.isAvailable && !metaState.isConnected && !provider) {
            setIsNoExtensionModalOpen(true);
          }

          if (metaState.isAvailable && metaState.isConnected) {
            setIsConnectedModalOpen(true);
          }
          connectMetamask('fromButton');
          sendGAEvent({
            category: 'Header',
            event: 'Click Connect Wallet',
            label: 'Crypto Wallet',
          });
        }}
      >
        <span className={styles.buttonContent}>
          <div className={styles.iconDiv}>
            {metaState.isConnected && (
              <div className={styles.onlineIndicator} />
            )}
            <Wallet
              className={device === 'desktop' ? styles.marginRight : ''}
            />
          </div>
          {device === 'desktop' &&
            (metaState.isConnected ? (
              truncate(metaState.account[0])
            ) : (
              <FormattedMessage defaultMessage='Connect Wallet' />
            ))}
        </span>
      </HeaderButton>
    </div>
  );
};

export default Metamask;
