import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';
import { useForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { entryFeeChoices } from '../../constants';
import {
  AnalyticsContext,
  AuthAndApiContext,
  RegionContext,
} from '../../contexts';
import { hydrateGameMode, sendGAEvent } from '../../helpers';
import ActivityIndicator from '../ActivityIndicator';
import Button from '../Button';
import GameModeTooltip from '../GameModeTooltip';
import Modal from '../Modal';
import UserInput from '../UserInput';
import styles from './DuelCreationModal.module.css';
import {
  FormGroup,
  FormInput,
  FormSelect,
  CommonError,
  yupCustom as yup,
} from '../Form/index';

type FormType = {
  entryFee: number;
  game: number | null;
  gameMode: number | null;
  platform: number | null;
  user2: number | null;
  rival?: string | null;
  [key: string]: any;
};

const defaultValues: FormType = {
  entryFee: 20,
  game: null,
  gameMode: null,
  platform: null,
  user2: null,
};

const isOpenQueryParameter = 'duelCreation';
const userIdQueryParameter = 'userId';

const DuelCreation = () => {
  const router = useRouter();
  const intl = useIntl();

  const { api, user } = useContext(AuthAndApiContext);
  const { region } = useContext(RegionContext);
  const { category } = useContext(AnalyticsContext);

  const [isDuelOpen, setIsDuelOpen] = useState<boolean>(true);
  const [userValue, setUserValue] = useState<any>(null);
  const [serverError, setServerError] = useState<any>(null);

  const userInvited: string | null =
    router.query[userIdQueryParameter] === undefined
      ? null
      : (router.query[userIdQueryParameter] as string);

  let hydratedGameMode: any = null;
  const validationSchema = yup.object({
    entryFee: yup.number(),
    game: yup.number().nullable(true).required(),
    gameMode: yup.number().nullable(true).required(),
    platform: yup.number().nullable(true).required(),
    user2: !isDuelOpen
      ? yup
          .number()
          .typeError(
            intl.formatMessage({
              defaultMessage: 'Please enter a valid username.',
            }),
          )
          .nullable(true)
          .required()
      : yup.number().nullable(true),
    optionalHydratedGameMode: yup.lazy((value: any) => {
      if (
        value !== null &&
        user![hydratedGameMode?.requiredAccountField] === null
      ) {
        return yup.string().required();
      }

      return yup.string();
    }),
  });

  // @ts-ignore
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    register,
    unregister,
    control,
    watch,
    setError,
  }: UseFormReturn<FormType> = methods;

  const watchGame = watch('game');
  const watchPlatform = watch('platform');

  const fetcher = (url: any) => fetch(url).then((r) => r.json());

  const { data: games } = useSWR<Game[]>(
    `/games/?is_active=true&region=${region.id}`,
  );

  const { data: platforms } = useSWR<Platform[]>(
    watchGame === null || watchGame === undefined
      ? null
      : `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/platforms/?is_active=true&game=${watchGame}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('platform') === null && data?.length === 1) {
          setValue('platform', data[0].id, {
            shouldValidate: true,
          });
        }
      },
    },
  );
  const { data: gameModes } = useSWR<GameMode[]>(
    watchPlatform === null || watchPlatform === undefined
      ? null
      : // eslint-disable-next-line max-len
        `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/game_modes/?is_active=true&game=${watchGame}&platform=${watchPlatform}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('gameMode') === null && data?.length === 1) {
          setValue('gameMode', data[0].id, {
            shouldValidate: true,
          });
        }
      },
    },
  );

  hydratedGameMode = useMemo<HydratedGameMode | null>(() => {
    if (gameModes === undefined || getValues('gameMode') === null) {
      return null;
    }

    const gameMode = gameModes.find((g) => g.id === getValues('gameMode'));

    if (gameMode === undefined) return null;

    return hydrateGameMode(gameMode);
  }, [gameModes, getValues('gameMode')]);

  useEffect(() => {
    if (userInvited === null) {
      setIsDuelOpen(true);
      setValue('user2', null);
    } else {
      setIsDuelOpen(false);
      setValue('user2', Number(userInvited), {
        shouldValidate: true,
      });
    }
  }, [router, userInvited]);

  useEffect(() => {
    if (getValues('game') === null && games?.length === 1) {
      setValue('game', games[0].id);
    }
  }, [games, getValues('game')]);

  const handleFormSubmit = async (data: FormType) => {
    setServerError({});

    if (
      user !== null &&
      hydratedGameMode !== null &&
      user![hydratedGameMode.requiredAccountField] === null
    ) {
      const meResponse = await api.patch('/users/me/', {
        [hydratedGameMode.requiredAccountField]: getValues(
          'optionalHydratedGameMode',
        ),
      });
      const meResponseJson = await meResponse.json();

      if (meResponse.ok) {
        await mutate('/users/me/', meResponseJson);
      } else {
        setServerError(meResponseJson);

        return;
      }
    }

    const payload = data;

    delete payload.rival;
    delete payload.optionalHydratedGameMode;

    const response = await api.post('/duels/', payload);
    const responseJson = await response.json();

    if (response.ok) {
      sendGAEvent({
        category,
        event: 'Click Create Duel',
      });

      await router.push(`/duels/duel?id=${responseJson.id}`);
    }
    setServerError(responseJson);

    if (responseJson?.user2) {
      setError('user2', {
        type: 'server',
        message: responseJson?.user2[0],
      });
    }

    if (responseJson?.entryFee) {
      setError('entryFee', {
        type: 'server',
        message: responseJson?.entryFee[0],
      });
    }
  };

  const hydrateGameModeFieldName = hydratedGameMode
    ? (hydratedGameMode?.requiredAccountField as string)
    : '';

  return games === undefined ? (
    <ActivityIndicator />
  ) : (
    <>
      {isSubmitting && <ActivityIndicator takeOver />}

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <CommonError errors={serverError?.nonFieldErrors} />
        <FormSelect
          control={control}
          disabled={getValues('game') === 110 || getValues('game') === 112}
          errorobj={errors}
          label={intl.formatMessage({ defaultMessage: 'Entry Fee' })}
          name='entryFee'
          onChange={(opt: any) => {
            const { value } = opt;

            sendGAEvent({
              category,
              event: 'Select Entry Fee',
              value,
            });
          }}
          options={entryFeeChoices?.map((option: any) => ({
            label: `${option} GAU Token`,
            value: option,
            disabled:
              (getValues('game') === 110 || getValues('game') === 112) &&
              option !== 0,
          }))}
          unregister={unregister}
        />
        <FormSelect
          control={control}
          errorobj={errors}
          label={intl.formatMessage({ defaultMessage: 'Game' })}
          name='game'
          onChange={(opt: any) => {
            const { value, label } = opt;

            setValue('platform', null);
            setValue('gameMode', null);

            if (value === 110 || value === 112) {
              setValue('entryFee', 0);
            }

            sendGAEvent({
              category,
              event: 'Select Game',
              value: label,
            });
          }}
          options={games?.map((option: any) => ({
            label: option.name,
            value: option.id,
          }))}
          unregister={unregister}
        />
        <FormSelect
          control={control}
          disabled={platforms === undefined}
          errorobj={errors}
          label={intl.formatMessage({ defaultMessage: 'Platform' })}
          name='platform'
          onChange={(opt: any) => {
            const { label } = opt;

            setValue('gameMode', null);

            sendGAEvent({
              category,
              event: 'Select Platform',
              value: label,
            });
          }}
          options={platforms?.map((option: any) => ({
            label: option.name,
            value: option.id,
          }))}
          unregister={unregister}
        />
        <FormSelect
          control={control}
          disabled={gameModes === undefined}
          errorobj={errors}
          label={intl.formatMessage({ defaultMessage: 'Game Mode' })}
          name='gameMode'
          onChange={(opt: any) => {
            const { label } = opt;

            sendGAEvent({
              category,
              event: 'Select Game Mode',
              value: label,
            });
          }}
          options={gameModes?.map((option: any) => ({
            label: option.name,
            value: option.id,
          }))}
          unregister={unregister}
        />
        <FormInput
          control={control}
          errorobj={errors}
          isVisible={
            hydratedGameMode !== null &&
            user![hydrateGameModeFieldName] === null
          }
          label={hydratedGameMode?.requiredAccountFieldStatus}
          name='optionalHydratedGameMode'
          unregister={unregister}
        />
        <FormSelect
          control={control}
          errorobj={errors}
          label={intl.formatMessage({ defaultMessage: 'Rival' })}
          name='rival'
          onChange={(opt: any) => {
            const { value } = opt;

            setIsDuelOpen(value === 'open');

            if (value === 'invited') {
              sendGAEvent({
                category,
                event: 'Select Opponent',
              });
            }
          }}
          options={[
            {
              label: intl.formatMessage({
                defaultMessage: 'List my duel as open',
              }),
              value: 'open',
            },
            {
              label: intl.formatMessage({
                defaultMessage: 'I want to invite a user to duel',
              }),
              value: 'invite',
            },
          ]}
          unregister={unregister}
        />
        {!isDuelOpen && (
          <FormGroup>
            <UserInput
              control={control}
              errorobj={errors}
              label={intl.formatMessage({
                defaultMessage: 'Opponent Username',
              })}
              name='user2'
              onChange={(userId) => {
                setValue('user2', userId, { shouldValidate: true });
                setUserValue(userId);
              }}
              ref={register}
              regionId={region.id}
              unregister={unregister}
              value={userValue || null}
            />
          </FormGroup>
        )}
        {getValues('gameMode') !== null && (
          <FormGroup className={styles.gameModeFormGroup}>
            <GameModeTooltip gameModeId={getValues('gameMode') || null} />
          </FormGroup>
        )}
        <Button disabled={isSubmitting} fullWidth type='submit'>
          <FormattedMessage defaultMessage='Create Duel' />
        </Button>
      </form>
    </>
  );
};

export default function DuelCreationModal() {
  const router = useRouter();
  const intl = useIntl();

  const { user } = useContext(AuthAndApiContext);

  const isOpen = router.query[isOpenQueryParameter] === 'true';

  async function onClose() {
    const {
      [isOpenQueryParameter]: _,
      [userIdQueryParameter]: __,
      ...query
    } = router.query;

    await router.push({
      pathname: router.pathname,
      query,
    });
  }

  return user === null ? null : (
    <Modal
      className={styles.duelCreationModal}
      isOpen={isOpen}
      onClose={() => onClose()}
      title={intl.formatMessage({ defaultMessage: 'Create a Duel' })}
    >
      <DuelCreation />
    </Modal>
  );
}
