import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR, { mutate } from 'swr';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { entryFeeChoices } from '../../constants';
import {
  AnalyticsContext,
  AuthAndApiContext,
  BreakpointContext,
  RegionContext,
} from '../../contexts';
import { hydrateGameMode, sendGAEvent } from '../../helpers';
import Button from '../Button';
import GameModeTooltip from '../GameModeTooltip';
import Modal from '../Modal';
import RequiredAccountFieldDisplay from '../RequiredAccountFieldDisplay';
import styles from './LeaderboardTournamentQuickDuel.module.css';
import { CommonError, FormInput, FormSelect, yupCustom as yup } from '../Form';
import ActivityIndicator from '../ActivityIndicator';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    leaderboard: Leaderboard;
  }
>;

type FormType = {
  entryFee: number;
  gameMode: number | null;
  requiredAccountField?: string;
};

const defaultValues: FormType = {
  entryFee: 20,
  gameMode: null,
  requiredAccountField: '',
};

export default function LeaderboardTournamentQuickDuel({
  className,
  leaderboard,
  ...props
}: Props) {
  const intl = useIntl();
  const router = useRouter();
  const { category } = useContext(AnalyticsContext);
  const { device } = useContext(BreakpointContext);
  const { api, user } = useContext(AuthAndApiContext);
  const { region } = useContext(RegionContext);

  const [isModalActionDisabled, setIsModalActionDisabled] =
    useState<boolean>(false);
  const [serverError, setServerError] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  let hydratedGameMode: any = null;
  const validationSchema = yup.object({
    entryFee: yup.number(),
    gameMode: yup.number().nullable(true).required(),
    requiredAccountField: yup.lazy((value: any) => {
      if (
        value !== null &&
        user![hydratedGameMode?.requiredAccountField] === null
      ) {
        return yup.string().required();
      }

      return yup.string();
    }),
  });

  // @ts-ignore
  const methods = useForm<FormType>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    watch,
    setError,
    control,
    unregister,
  }: UseFormReturn<FormType> = methods;

  const watchGameMode = watch('gameMode');

  const fetcher = (url: any) => fetch(url).then((r) => r.json());

  const { data: gameModes } = useSWR<GameMode[]>(
    leaderboard.game === null || leaderboard.game === undefined
      ? null
      : `${process.env.NEXT_PUBLIC_GAMERARENA_API_URL}/games/game_modes?is_active=true&game=${leaderboard.game}&region=${region.id}`,
    fetcher,
    {
      onSuccess: (data /* , key, config */) => {
        if (getValues('gameMode') === null && data?.length === 1) {
          setValue('gameMode', data[0].id, {
            shouldValidate: true,
          });
        }
      },
    },
  );

  const { data: gameMode } = useSWR<GameMode>(
    watchGameMode === null
      ? null
      : `/games/game_modes/${watchGameMode}/?region=${region.id}`,
  );

  hydratedGameMode = useMemo<HydratedGameMode | null>(() => {
    if (gameMode === undefined) return null;

    return hydrateGameMode(gameMode);
  }, [gameMode]);

  useEffect(() => {
    if (leaderboard.game === 110 || leaderboard.game === 112) {
      setValue('entryFee', 0);
    } else {
      setValue('entryFee', defaultValues.entryFee);
    }
  }, [leaderboard]);

  const subInfo = useMemo(
    () => (
      <div className={styles.sub}>
        <GameModeTooltip
          className={styles.gameModeTooltip}
          gameModeId={watchGameMode}
        />
      </div>
    ),
    [watchGameMode],
  );

  const handleFormSubmit = async (/* data: FormType */) => {
    setIsModalOpen(true);
  };

  return user === null ? null : (
    <>
      <Modal
        actions={
          <>
            <Button
              disabled={isModalActionDisabled}
              onClick={() => setIsModalOpen(false)}
              type='button'
              variant='secondary'
            >
              <FormattedMessage defaultMessage='Cancel' />
            </Button>

            <Button
              disabled={isModalActionDisabled}
              onClick={async () => {
                // this is here only as a type guard
                if (hydratedGameMode === null) return;

                setIsModalActionDisabled(true);

                if (getValues('requiredAccountField') !== null) {
                  const requiredAccountFieldResponse = await api.patch(
                    '/users/me/',
                    {
                      [hydratedGameMode.requiredAccountField]: getValues(
                        'requiredAccountField',
                      ),
                    },
                  );

                  const requiredAccountFieldResponseJson =
                    await requiredAccountFieldResponse.json();

                  if (!requiredAccountFieldResponse.ok) {
                    // patch failed, let's not continue.
                    return;
                  }

                  await mutate('/users/me/', requiredAccountFieldResponseJson);
                }

                const payload = getValues();

                delete payload.requiredAccountField;

                const response = await api.post('/duels/get_or_create/', {
                  ...payload,
                  leaderboard: leaderboard.id,
                });

                const responseJson = await response.json();

                if (response.ok) {
                  sendGAEvent({
                    category,
                    event: 'Click Find Duel',
                    label: leaderboard.name,
                  });

                  await router.push(`/duels/duel?id=${responseJson.id}`);
                } else {
                  if (responseJson?.entryFee) {
                    setError('entryFee', {
                      type: 'server',
                      message: responseJson?.entryFee[0],
                    });
                  }

                  setIsModalActionDisabled(false);
                  setServerError(responseJson);
                }

                setIsModalOpen(false);
              }}
              type='button'
            >
              <FormattedMessage defaultMessage='Join Duel' />
            </Button>
          </>
        }
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <FormattedMessage defaultMessage="You will join a duel based on your rating or create a new duel if there aren't any open. Continue?" />
      </Modal>

      {isSubmitting && <ActivityIndicator takeOver />}

      <section
        {...props}
        className={classNames(styles.leaderboardTournamentQuickDuel, className)}
      >
        <div className={styles.header}>
          <h4>
            <FormattedMessage defaultMessage='Matchmaking' />
          </h4>
        </div>

        <div className={styles.body}>
          <p className={styles.muted}>
            <FormattedMessage defaultMessage='Find the opponent with the closest rank.' />
          </p>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <CommonError errors={serverError?.nonFieldErrors} />
            <CommonError errors={serverError?.leaderboard} />

            <fieldset className={styles.fieldset}>
              <FormSelect
                control={control}
                errorobj={errors}
                formGroupClassName={styles.formGroup}
                label={intl.formatMessage({ defaultMessage: 'Game Mode' })}
                name='gameMode'
                onChange={(opt: any) => {
                  const { label } = opt;

                  sendGAEvent({
                    category,
                    event: 'Select Game Mode',
                    label: leaderboard.name,
                    value: label,
                  });
                }}
                options={gameModes?.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                size='large'
                unregister={unregister}
              />

              <FormInput
                control={control}
                errorobj={errors}
                formGroupClassName={styles.formGroup}
                isVisible={
                  hydratedGameMode !== null &&
                  user![hydratedGameMode?.requiredAccountField] === null
                }
                label={
                  <RequiredAccountFieldDisplay
                    field={hydratedGameMode?.requiredAccountFieldStatus}
                  />
                }
                name='requiredAccountField'
                size='large'
                unregister={unregister}
              />

              <FormSelect
                control={control}
                disabled={
                  getValues('gameMode') === null ||
                  leaderboard.game === 110 ||
                  leaderboard.game === 112
                }
                errorobj={errors}
                formGroupClassName={styles.formGroup}
                label={intl.formatMessage({ defaultMessage: 'Entry Fee' })}
                name='entryFee'
                options={entryFeeChoices?.map((option: any) => ({
                  label: `${option} GAU Token`,
                  value: option,
                  disabled:
                    (leaderboard.game === 110 || leaderboard.game === 112) &&
                    option !== 0,
                }))}
                size='large'
                unregister={unregister}
              />

              {device === 'mobile' && subInfo}

              <Button
                className={styles.button}
                disabled={isSubmitting}
                type='submit'
              >
                <FormattedMessage defaultMessage='Join Duel' />
              </Button>
            </fieldset>
          </form>

          {device !== 'mobile' && subInfo}
        </div>
      </section>
    </>
  );
}
