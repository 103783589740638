import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AnalyticsContext,
  AuthAndApiContext,
  BreakpointContext,
} from '../../contexts';
import { createGtag } from '../../helpers';
import Metamask from '../../pages/metamask';
import Button from '../Button';
import Container from '../Container';
import LandingMenu from '../Landing/LandingMenu';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import NotificationMenu from '../NotificationMenu';
import OnlineUsers from '../OnlineUsers';
import UserMenu from '../UserMenu';
import WalletMenu from '../WalletMenu';
import styles from './Header.module.css';

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['header']> & {
  className?: string;
};

export default function Header({ className, ...props }: Props) {
  const router = useRouter();
  const { device } = useContext(BreakpointContext);
  const { category } = useContext(AnalyticsContext);
  const { user } = useContext(AuthAndApiContext);
  const inLanding =
    router.pathname === '/landing/v1' || router.pathname === '/landing/v2';

  return (
    <header {...props} className={classNames(styles.header, className)}>
      <Container
        className={classNames(
          styles.container,
          router.pathname === '/games' && styles.games,
          user === null && styles.notAuthenticated,
          inLanding && styles.forLanding,
        )}
        fluid
      >
        {router.pathname === '/games' ? (
          <div className={styles.gamesContent}>
            <div className={styles.logo}>
              <Link href={user === null ? '/' : '/arena'}>
                <a className={styles.logoLink}>
                  <Logo className={styles.logo} />
                </a>
              </Link>
            </div>

            <Link href='/arena' passHref>
              <Button variant='secondary'>
                <FormattedMessage defaultMessage='Go to Arena' />
              </Button>
            </Link>
          </div>
        ) : (
          <>
            {user === null ? (
              <>
                {device !== 'mobile' && !inLanding && (
                  <Link href='/games' passHref>
                    <Button size='small' variant='primary'>
                      <FormattedMessage defaultMessage='Fun Games' />
                    </Button>
                  </Link>
                )}

                {device !== 'mobile' && (
                  <div className={styles.logoWrapper}>
                    <Link href={router.pathname}>
                      <a
                        aria-label='Gamer Arena'
                        className={classNames(
                          device === 'desktop' && styles.logoWithSpace,
                        )}
                      >
                        <Logo className={styles.logo} />
                      </a>
                    </Link>
                  </div>
                )}

                {device !== 'mobile' && inLanding && (
                  <LandingMenu device='desktop' />
                )}
                <div className={styles.buttonsContainer}>
                  <div className={styles.languageSelector}>
                    <LanguageSelector />
                  </div>

                  {device !== 'mobile' && (
                    <Link
                      href={
                        inLanding
                          ? `${router.pathname}/?action=sign-in`
                          : '/?action=sign-in'
                      }
                      passHref
                      scroll={false}
                    >
                      <Button size='small' variant='primary'>
                        <FormattedMessage defaultMessage='Sign In' />
                      </Button>
                    </Link>
                  )}
                </div>

                {device === 'mobile' && inLanding && (
                  <LandingMenu device='mobile' />
                )}
              </>
            ) : (
              <>
                {device !== 'mobile' && (
                  <div className={styles.logoWrapper}>
                    <Link href={router.pathname}>
                      <a
                        aria-label='Gamer Arena'
                        className={classNames(styles.logoWithSpace)}
                      >
                        <Logo className={styles.logo} />
                      </a>
                    </Link>
                  </div>
                )}

                {device !== 'mobile' && inLanding && (
                  <LandingMenu device='desktop' />
                )}

                {device !== 'mobile' && !inLanding && (
                  <Link
                    href={{
                      pathname: router.pathname,
                      query: {
                        ...router.query,
                        duelCreation: 'true',
                      },
                    }}
                    passHref
                  >
                    <Button
                      data-gtag={createGtag({
                        category,
                        event: 'Open Custom Duel Modal',
                        label: 'Header',
                      })}
                      size='small'
                      variant='secondary'
                    >
                      <FormattedMessage defaultMessage='Create Duel' />
                    </Button>
                  </Link>
                )}

                {device !== 'mobile' && !inLanding && (
                  <Link href='/games' passHref>
                    <Button size='small' variant='primary'>
                      <FormattedMessage defaultMessage='Fun Games' />
                    </Button>
                  </Link>
                )}

                {device === 'mobile' && <UserMenu />}

                {device === 'mobile' && <WalletMenu />}

                {device === 'mobile' && !inLanding && (
                  <Metamask device='mobile' />
                )}

                <div className={styles.separator} />

                {device !== 'mobile' && !inLanding && (
                  <Metamask device='desktop' />
                )}

                {device !== 'mobile' && <WalletMenu />}

                <NotificationMenu />

                <OnlineUsers />

                {device !== 'mobile' && <UserMenu />}

                {device === 'mobile' && inLanding && (
                  <LandingMenu device='mobile' />
                )}
              </>
            )}
          </>
        )}
      </Container>
    </header>
  );
}
