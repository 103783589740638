import classNames from 'classnames';
/* import Link from 'next/link'; */
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import SwiperCore, { Autoplay, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSWR from 'swr';
/* import Link from 'next/link'; */
import { LocalizationContext, RegionContext } from '../../contexts';
import ActivityIndicator from '../ActivityIndicator';
import Container from '../Container';
/* import { ReactComponent as Arrow } from './arrow.svg'; */
import styles from './GameBrowser.module.css';
import { ReactComponent as Logo } from './logo.svg';
import Robot from './robot.jpg';
import Car from './car.png';
import Knight from './knight.png';
import Ball from './ball.png';
/* import Button from '../Button'; */

type Props = React.PropsWithoutRef<JSX.IntrinsicElements['div']> & {
  className?: string;
  fullWidth?: boolean;
};

SwiperCore.use([Autoplay, Parallax]);

export default function GameBrowser({
  className,
  fullWidth = false,
  ...props
}: Props) {
  const { region } = useContext(RegionContext);
  const { dir } = useContext(LocalizationContext);
  const { data: games } = useSWR<Game[]>(
    `/games/?is_active=true&region=${region.id}`,
  );
  const newGames = [
    {
      id: 1000,
      isNew: true,
      name: 'Rider',
      image: Car.src,
    },
    {
      id: 1001,
      isNew: true,
      name: 'Arena Jumper',
      image: Knight.src,
    },
    {
      id: 1002,
      isNew: true,
      name: 'Hoop Shot',
      image: Ball.src,
    },
    {
      id: 1003,
      isNew: true,
      name: 'Space Fighter',
      image: Robot.src,
    },
  ];
  // @ts-ignore
  const gamesArray: Array[] = [];
  if (games) {
    for (let l = 0; l < newGames.length; l += 1) {
      gamesArray.push(games[l]);
      gamesArray.push(newGames[l]);
    }

    for (
      let l = newGames.length;
      l > newGames.length - 1 && l < games.length;
      l += 1
    ) {
      gamesArray.push(games[l]);
    }
  }

  const swiperRef = React.useRef<SwiperCore>();

  const onSwiperInit = (Swip: SwiperCore): void => {
    swiperRef.current = Swip;
  };

  return (
    <section {...props} className={classNames(styles.gamesBrowser, className)}>
      <Container className={classNames(fullWidth && styles.noPadding)}>
        <p>
          <FormattedMessage defaultMessage='Games in Platform' />
        </p>

        <div className={styles.content}>
          {/*  <button
            aria-label='previous'
            className={classNames(styles.navigation, styles.prev)}
            type='button'
          >
            <Arrow />
          </button> */}

          <Swiper
            autoplay={{
              delay: 5,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            className={styles.slider}
            dir={dir}
            grabCursor
            id='gamesBrowser'
            loop={!!games}
            /*  navigation={{
               nextEl: `.${styles.next}`,
               prevEl: `.${styles.prev}`,
               disabledClass: `${styles.disabled}`,
             }} */
            onInit={onSwiperInit}
            slidesPerView='auto'
            spaceBetween={0}
            speed={3000}
            watchSlidesProgress
          >
            {games === undefined ? (
              <ActivityIndicator />
            ) : (
              <>
                {gamesArray?.map((g) => (
                  <SwiperSlide className={styles.slide} key={g.id}>
                    {/* <Link href={`/duels?game=${g.id}`}> */}
                    <a className={styles.anchor}>
                      {g && g.isNew && (
                        <>
                          <span className={styles.poweredBy}>
                            <FormattedMessage defaultMessage='Powered by' />
                            <Logo className={styles.gamerArenaLogo} />
                          </span>
                          <span className={styles.comingSoon}>
                            <FormattedMessage defaultMessage='Coming Soon' />
                          </span>
                        </>
                      )}
                      {/* Yeni tasarıma göre servisten gelen logolar eklenecek */}
                      {/* <span className={styles.gameHeader}>{g.name}</span> */}
                      <img alt={g.name} src={g.image} />
                    </a>
                    {/* </Link> */}
                  </SwiperSlide>
                ))}
              </>
            )}
          </Swiper>

          {/* <button
            aria-label='next'
            className={classNames(styles.navigation, styles.next)}
            type='button'
          >
            <Arrow />
          </button> */}
        </div>
      </Container>

      {/* <Link href='/landing/v1' passHref>
        <Button className={styles.signUpForFree} size='large' variant='primary'>
          <FormattedMessage defaultMessage='Sign Up for Free' />
        </Button>
      </Link> */}
    </section>
  );
}
