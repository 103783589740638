import classNames from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import Avatar from '../Avatar';
import Countdown from '../Countdown';
import GACoin from '../GACoin';
import LeaderboardTournamentStatusDisplay from '../LeaderboardTournamentStatusDisplay';
import styles from './LeaderboardTournamentCard.module.css';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    leaderboard: Leaderboard;
  }
>;

export default function LeaderboardTournamentCard({
  className,
  leaderboard,
  ...props
}: Props) {
  const { data: game } = useSWR<Game>(`/games/${leaderboard.game}/`);
  const [isCountdownVisible, setIsCountdownVisible] = useState<boolean>(true);

  return game === undefined ? null : (
    <div
      {...props}
      className={classNames(styles.leaderboardTournamentCard, className)}
    >
      <Link href={`/tournaments/leaderboard/?id=${leaderboard.id}`}>
        <a className={styles.link}>
          <div
            className={styles.header}
            style={{ backgroundImage: `url(${game.image})` }}
          >
            <div className={styles.headerWrapper}>
              <div className={styles.game}>
                <Avatar image={game.image} size={36} />
                <p className={styles.name}>{game.name}</p>
              </div>

              <div className={styles.status}>
                <LeaderboardTournamentStatusDisplay
                  leaderboardStatus={leaderboard.status}
                />
              </div>
            </div>
            {leaderboard.status !== 'FINISHED' &&
              leaderboard.status !== 'IN_REVIEW' &&
              isCountdownVisible && (
                <div className={styles.countdown}>
                  <div className={styles.countdownContainer}>
                    <p className={styles.remaining}>
                      <FormattedMessage defaultMessage='Remaining Time' />
                    </p>
                    <Countdown
                      className={styles.time}
                      color='white'
                      date={
                        leaderboard.status === 'FUTURE'
                          ? leaderboard.startsAt
                          : leaderboard.endsAt
                      }
                      onComplete={() => setIsCountdownVisible(false)}
                    />
                  </div>
                </div>
              )}
          </div>
          <div className={styles.body}>
            <div className={styles.detailHeader}>
              <p className={styles.title}>{leaderboard.name}</p>
            </div>
            <div className={styles.reward}>
              <GACoin className={styles.gaCoin} />
              <p>
                <FormattedMessage
                  defaultMessage='{totalReward} Total Reward'
                  values={{
                    totalReward: leaderboard.totalReward,
                  }}
                />
              </p>
            </div>
          </div>
          <div className={styles.footer}>
            <p className={styles.muted}>
              <FormattedMessage defaultMessage='Starting Date' />
              {' - '}
              <FormattedMessage defaultMessage='Ending Date' />:
            </p>
            <div className={styles.info}>
              {dayjs(leaderboard.startsAt).format('DD MMM, HH:mm')}
              {' - '}
              {dayjs(leaderboard.endsAt).format('DD MMM, HH:mm')}
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
}
