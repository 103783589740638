import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './LandingOurProductMobile.module.css';
import LandingProductCard from '../LandingProductCard';
import { ReactComponent as Competition } from './competition.svg';
import { ReactComponent as Opponent } from './opponent.svg';
import { ReactComponent as Reward } from './reward.svg';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['div']>,
  {
    image?: any;
  }
>;

export default function LandingOurProductMobile({
  className,
  /* image, */
  ...props
}: Props) {
  return (
    <div {...props} className={classNames(styles.ourProductMobile, className)}>
      {/* {image && <img alt='Gamer Arena' src={image.src} />} */}
      <h3>
        <FormattedMessage defaultMessage='How to Play?' />
      </h3>
      <div className={styles.cards}>
        <LandingProductCard
          icon={<Competition />}
          subTitle={
            <FormattedMessage defaultMessage='Find similarly skilled players to compete on with the games you love.' />
          }
          title={<FormattedMessage defaultMessage='Find an Opponent' />}
        />
        <LandingProductCard
          icon={<Opponent />}
          subTitle={
            <FormattedMessage defaultMessage='Participate in duels and tournaments to show what you are made of.' />
          }
          title={<FormattedMessage defaultMessage='Show Yourself' />}
        />
        <LandingProductCard
          icon={<Reward />}
          subTitle={
            <FormattedMessage defaultMessage='Win GAU Tokens with your skills.' />
          }
          title={<FormattedMessage defaultMessage='Earn GAU Token' />}
        />
      </div>
    </div>
  );
}
