/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { FormGroupContext } from '../../contexts';
import BoxLabel from '../BoxLabel';
import styles from './TextInput.module.css';
import showPwdIcon from './show-password.svg';
import hidePwdIcon from './hide-password.svg';

export type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['input']>,
  {
    id: string;
    label?: React.ReactNode;
    size?: FormComponentSize;
    customClasses?: string;
  }
>;

const sizeStyles: Record<FormComponentSize, string> = {
  xlarge: styles.sizeXlarge,
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

function TextInput(
  {
    className,
    id,
    label,
    onBlur,
    onFocus,
    size = 'medium',
    placeholder,
    value,
    customClasses,
    ...props
  }: Props,
  ref: React.Ref<HTMLDivElement>,
) {
  const { error } = useContext(FormGroupContext);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <div
      className={classNames(
        styles.textInput,
        sizeStyles[size],
        error && styles.hasError,
        props.type === 'password' && styles.ikonInput,
        label === undefined && styles.noLabel,
        className,
      )}
      ref={ref}
    >
      {label && (
        <BoxLabel
          htmlFor={id}
          onTop={placeholder !== undefined || isFocused || value !== ''}
          size={size}
        >
          {label}
        </BoxLabel>
      )}

      <input
        {...props}
        className={classNames(
          styles.input,
          label && styles.withLabel,
          customClasses && customClasses,
        )}
        id={id}
        onBlur={(event) => {
          setIsFocused(false);
          onBlur !== undefined && onBlur(event);
        }}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus !== undefined && onFocus(event);
        }}
        placeholder={placeholder}
        type={isRevealPwd ? 'text' : props.type}
        value={value}
      />

      {props.type === 'password' && (
        <img
          alt={isRevealPwd ? 'Hide password' : 'Show password'}
          className={styles.pwdIcon}
          onClick={() => setIsRevealPwd((prevState) => !prevState)}
          src={isRevealPwd ? hidePwdIcon : showPwdIcon}
        />
      )}
    </div>
  );
}

export default React.forwardRef(TextInput);
