/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { AuthAndApiContext, ToastsContext } from '../contexts';
import { MetaStateContext, MetaDispatchContext } from '../store/store';

const chains = (chainId) => {
  if (!!Number(chainId) && chainId.length > 9) {
    return 'local';
  }
  switch (chainId) {
    case '1':
      return 'mainnet';
    case '3':
      return 'ropsten';
    case '4':
      return 'rinkeby';
    case '5':
      return 'goerli';
    case '42':
      return 'kovan';
    default:
      return `unknown`;
  }
};

const useMetamask = () => {
  const state = useContext(MetaStateContext);
  const dispatch = useContext(MetaDispatchContext);
  const { userBasicToken } = useContext(AuthAndApiContext);
  const _isMounted = useRef(true);
  const _isConnectCalled = useRef(false);
  const [provider] = useState(window.ethereum);
  const [buttonPressed, setButtonPressed] = useState(false);
  const { addToast } = useContext(ToastsContext);
  const intl = useIntl();
  useEffect(
    () => () => {
      _isMounted.current = false;
    },
    [window.ethereum],
  );

  window.ethereum &&
    window.ethereum.on('accountsChanged', (accounts) => {
      localStorage.setItem('metamaskConnected', '1');
      const metamaskStorage = localStorage.getItem('metamaskConnected');
      if (accounts.length || metamaskStorage) {
        dispatch({ type: 'SET_CONNECTED', payload: true });
        dispatch({ type: 'SET_ACCOUNT', payload: accounts });
      }
      if (!accounts.length) localStorage.removeItem('metamaskConnected');
      dispatch({ type: 'SET_CONNECTED', payload: false });
      dispatch({ type: 'SET_ACCOUNT', payload: accounts });
    });

  const connect = async (Web3Interface, settings = {}, fromButton) => {
    const metamaskStorage = localStorage.getItem('metamaskConnected');

    setButtonPressed(!!fromButton);

    if (!provider) throw Error('Metamask is not available.');
    if (!Web3Interface)
      throw Error(
        'Web3 Provider is required. You can use either ethers.js or web3.js.',
      );
    /* if (!_isMounted.current) throw Error('Component is not mounted.'); */
    if (_isConnectCalled.current && !metamaskStorage)
      addToast({
        content: intl.formatMessage({
          defaultMessage:
            'Open your wallet application and check the connection request.',
        }),
        kind: 'warning',
      });
    _isConnectCalled.current = true;

    const _web3 = new Web3Interface(
      ...(Object.keys(settings).length ? [provider, settings] : [provider]),
    );
    dispatch({ type: 'SET_WEB3', payload: _web3 });
    await getAccounts({ requestPermission: true });
    await getChain();

    window.ethereum.on('accountsChanged', (accounts) => {
      if (!accounts.length) localStorage.removeItem('metamaskConnected');
      dispatch({ type: 'SET_CONNECTED', payload: false });
      dispatch({ type: 'SET_ACCOUNT', payload: accounts });
    });

    window.ethereum.on('chainChanged', (chainId) => {
      const _chainId = parseInt(chainId, 16).toString();
      const _chainInfo = { id: _chainId, name: chains(_chainId) };
      dispatch({ type: 'SET_CHAIN', payload: _chainInfo });
    });

    _isConnectCalled.current = false;
  };

  const metamaskRequest = async (accountId) => {
    await axios.post(
      `${process.env.NEXT_PUBLIC_GAMERARENA_SOCIALAUTH_URL}/api/users/addWallet`,
      {
        walletId: accountId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${userBasicToken}`,
        },
      },
    );
  };

  const getAccounts = async (
    { requestPermission } = { requestPermission: false },
  ) => {
    if (!provider) {
      console.warn('Metamask is not available.');
      return;
    }
    try {
      const accounts = await provider.request({
        method: requestPermission ? 'eth_requestAccounts' : 'eth_accounts',
        params: [],
      });
      localStorage.setItem('metamaskConnected', '1');
      const metamaskConnected = localStorage.getItem('metamaskConnected');
      if (accounts.length || metamaskConnected) {
        dispatch({ type: 'SET_CONNECTED', payload: true });
        dispatch({ type: 'SET_ACCOUNT', payload: accounts });

        if (buttonPressed && accounts) {
          metamaskRequest(String(accounts[0]));
        }
      }
      return accounts;
    } catch (error) {
      throw Error(error);
    }
  };

  const getChain = async () => {
    if (!provider) {
      console.warn('Metamask is not available.');
      return;
    }
    try {
      const chainId = await provider.request({
        method: 'net_version',
        params: [],
      });
      const _chainInfo = { id: chainId, name: chains(chainId) };
      dispatch({
        type: 'SET_CHAIN',
        payload: _chainInfo,
      });
      return _chainInfo;
    } catch (error) {
      throw Error(error);
    }
  };

  const setMetaState = (content) => {
    dispatch(content);
  };

  return {
    connect,
    getAccounts,
    getChain,
    setMetaState,
    metaState: { ...state, isAvailable: !!provider },
  };
};

export default useMetamask;
