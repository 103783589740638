import classNames from 'classnames';
import React from 'react';
import styles from './WalletCurrencyRate.module.css';
import { ReactComponent as Currency } from './currency.svg';
import GACoin from '../GACoin';

type Props = Overwrite<
  React.PropsWithoutRef<JSX.IntrinsicElements['section']>,
  {
    gauBalance: number;
    region: Region;
  }
>;

export default function WalletCurrencyRate({
  className,
  gauBalance,
  region,
  ...props
}: Props) {
  return (
    <section
      {...props}
      className={classNames(styles.walletCurrencyRate, className)}
    >
      <div className={styles.wrapper}>
        <div className={styles.info}>
          <GACoin className={styles.gaCoin} />

          <h3>{gauBalance} GAU</h3>
        </div>

        <div className={styles.info}>
          <Currency />

          <h3>
            {parseFloat((gauBalance * region.exchangeRate).toFixed(2))}{' '}
            {region.currencyCode}
          </h3>
        </div>
      </div>

      <p className={styles.equality}>=</p>
    </section>
  );
}
